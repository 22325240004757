// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

// Create a context
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const dashboardValidUrls = [
      "/dashboard", 
      "/dashboard/insights",
      "/dashboard/insights/data",
      "/dashboard/account-settings/profile", 
      "/dashboard/account-settings/company-details", 
      "/dashboard/fields/all", 
      "/dashboard/fields/add",
      "/dashboard/subscriptions",
      "/dashboard/store"
    ];

    // Use regular expressions to match dynamic URLs
    const dashboardValidUrlPatterns = [
      /^\/dashboard\/fields\/edit\/[a-zA-Z0-9_-]+$/,
      /^\/dashboard\/fields\/edit\/[a-zA-Z0-9_-]+\/preview$/,
      /^\/dashboard\/insights\/generated\/\d+$/
    ];

    const authValidUrls = ["/login", "/register", "/reset-password"];

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);

      if (user) {
        const isDashboardRoute = dashboardValidUrls.includes(location.pathname) || 
          dashboardValidUrlPatterns.some(pattern => pattern.test(location.pathname));

        if (!isDashboardRoute) {
          navigate("/dashboard");
        }
      }

      if (!user) {
        const isAuthRoute = authValidUrls.includes(location.pathname);
        if (!isAuthRoute) {
          navigate("/login");
        }
      }

      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [location.pathname, navigate, setCurrentUser, setIsLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
