import React, { useState } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import logo from "../../assets/images/fillyform-logo.svg";
import { Link } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addNotification } = useNotification();

  const validateField = (name, value) => {
    const strongEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

    let error = "";
    if (name === "email") {
      if (!value) {
        error = "Email is required.";
      } else if (!strongEmailRegex.test(value)) {
        error = "Please enter a valid email address.";
      }
    }
    return error;
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    const emailError = validateField("email", formData.email);

    if (emailError) {
      formIsValid = false;
      newErrors["email"] = emailError;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Optionally validate fields on change
    const fieldError = validateField(name, value);
    setErrors({ ...errors, [name]: fieldError });
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case "auth/invalid-email":
        return "The email address is not valid.";
      case "auth/user-not-found":
        return "No user found with this email address.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      await sendPasswordResetEmail(auth, formData.email);
      setIsSubmitting(false);

      addNotification({
        title: `Success!`,
        message: `The email was sent to ${formData.email}.`,
        icon: "check_circle",
        type: "success",
      });
    } catch (error) {
      setIsSubmitting(false);
      setErrors({ form: getErrorMessage(error.code) });
    }
  };

  return (
    <div className="auth-container">
      <img src={logo} alt="FillyForm" className="logo" />
      <form onSubmit={handleSubmit}>
        <div className="form-header">
          <h1>Recover your password</h1>
          <p>
            Please enter the email address you’d like your password reset
            information sent to.
          </p>
        </div>

        <fieldset>
          <div className="form-field">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={errors.email ? "has-error" : ""}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
        </fieldset>
        {errors.form && <div className="error">{errors.form}</div>}
        <button
          type="submit"
          className={`primary-button ${isSubmitting ? "loading" : ""}`}
          disabled={isSubmitting}
        >
          Recover password
        </button>
        <Link to="/login" className="link">Back to login</Link>
      </form>
      <p className="copyrights">
        © {new Date().getFullYear()} Answers Automate
      </p>
    </div>
  );
};

export default ResetPassword;