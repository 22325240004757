import React, { useState, useEffect, useCallback } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { collection, query, where, Timestamp, onSnapshot, orderBy, limit } from "firebase/firestore";
import { Routes, Route } from "react-router-dom";
import { db } from "../../../firebase";
import InsightsData from "./InsightsData";
import GeneratedContent from "./GeneratedContent";
import DashboardTabs from "../../../components/general/DashboardTabs";

const Insights = () => {
  const { dashboardData } = useDashboard();

  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const tabs = [
    { title: "Data", url: "/dashboard/insights/data" },
    { title: "Generated content", url: "/dashboard/insights/generated/1" },
  ];

  const publishedFields = dashboardData?.company?.fields?.filter(field => field.published === "true") || [];

  useEffect(() => {
    if (publishedFields.length > 0) {
      setSelectedFieldId(publishedFields[0].id);
    }
  }, [publishedFields]);

  useEffect(() => {
    let unsubscribeGeneratedData;

    if (dashboardData && selectedFieldId) {
      unsubscribeGeneratedData = subscribeToGeneratedData();
    }

    return () => {
      if (unsubscribeGeneratedData) unsubscribeGeneratedData();
    };
  }, [dashboardData, selectedFieldId]);

  const subscribeToGeneratedData = useCallback(() => {
    if (dashboardData) {
      const companyId = dashboardData.user.companyId;
      if (!companyId) {
        console.error("Company ID is missing in dashboardData");
        return;
      }

      const generatedDataRef = collection(db, "insights", companyId, "fields", selectedFieldId, "generated_data");

      return onSnapshot(generatedDataRef, (snapshot) => {
        const generatedData = [];
        snapshot.forEach((genDataDoc) => generatedData.push({ id: genDataDoc.id, ...genDataDoc.data() }));

        generatedData.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        setGeneratedData(generatedData);
        setLoading(false);
      }, (error) => {
        console.error("Error fetching generated data:", error);
        setLoading(false);
      });
    }
  }, [dashboardData, selectedFieldId]);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (direction) => {
    const totalPages = Math.ceil(generatedData.length / entriesPerPage);
    if (direction === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    }
  };

  return (
    <div>
      <DashboardTabs links={tabs} />
      <Routes>
        <Route path="data" element={<InsightsData dashboardData={dashboardData} selectedFieldId={selectedFieldId} setSelectedFieldId={setSelectedFieldId} />} />
        <Route
          path="generated/:page"
          element={
            <GeneratedContent
              dashboardData={dashboardData}
              selectedFieldId={selectedFieldId}
              setSelectedFieldId={setSelectedFieldId}
              generatedData={generatedData}
              entriesPerPage={entriesPerPage}
              setEntriesPerPage={setEntriesPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handlePageChange={handlePageChange}
              publishedFields={publishedFields}
              setGeneratedData={setGeneratedData}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Insights;
