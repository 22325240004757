import React, { useEffect, useState } from "react";
// import firebase from "../firebase";
import { useDashboard } from "../context/DashboardContext";
import logo from "../assets/images/fillyform-logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const Sidebar = () => {
  const { dashboardData } = useDashboard();

  const navigate = useNavigate();
  const location = useLocation();

  const [navigation, setNavigation] = useState([
    {
      name: "Profile",
      href: "/dashboard/account-settings/profile",
      path: "/dashboard/account-settings",
      icon: "person",
      current: false,
    },
    {
      name: "Insights",
      href: "/dashboard/insights/data",
      path: "/dashboard/insights",
      icon: "pie_chart",
      current: false,
    },
    // {
    //   name: "Insights",
    //   href: "/dashboard/insights",
    //   icon: "pie_chart",
    //   current: false,
    // },
    {
      name: "Fields",
      href: "/dashboard/fields/all",
      path: "/dashboard/fields",
      icon: "settings",
      current: false,
    },
    {
      name: "Subscriptions",
      href: "/dashboard/subscriptions",
      path: "/dashboard/subscriptions",
      icon: "cached",
      current: false,
    },
    {
      name: "Store",
      href: "/dashboard/store",
      path: "/dashboard/store",
      icon: "store",
      current: false,
    },
    // {
    //   name: "Subscriptions",
    //   href: "/dashboard/subscriptions",
    //   icon: "paid",
    //   current: false,
    // },
  ]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleNavigation = (href, itemName) => {
    navigate(href);
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setNavigation(updatedNavigation);
  };

  useEffect(() => {
    const newNavigation = navigation.map((item) => ({
      ...item,
      current: location.pathname.startsWith(item.path),
    }));
    setNavigation(newNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSignOut = async () => {
    try {
      await signOut(getAuth());
      navigate("/login"); // Redirect to login page after sign out
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div>
        <img src={logo} className="logo" alt="Filly Form" />

        <div className="user-details">
          <div className="avatar">
            {/* <img src={dashboardData?.user?.photoUrl} alt="" /> */}
            <span>{dashboardData?.user?.displayName[0]}</span>
          </div>
          <p>Welcome back, {dashboardData?.user?.displayName}</p>
        </div>

        <ul className="nav-items">
          {navigation.map((item) => (
            <li key={item.name}>
              <div
                onClick={() => handleNavigation(item.href, item.name)}
                className={classNames(item.current ? "active" : "")}
              >
                {item.icon && <span className={`icon`}>{item.icon}</span>}
                {item.name}
              </div>
            </li>
          ))}
        </ul>
        </div>
        <span className="sign-out" onClick={handleSignOut}>
        Sign Out <span className="icon">logout</span>
      </span>
      </div>
    </div>
  );
};

export default Sidebar;
