import React, { useState } from "react";
import { useDashboard } from "../../../context/DashboardContext";

const Subscriptions = () => {
  const { dashboardData, generateStripeLink } = useDashboard();
  const [manageLoading, setManageLoading] = useState(false);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const handleManageSubscription = async () => {
    setManageLoading(true);
    try {
      const url = await generateStripeLink();
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening Stripe portal:", error);
    }
    setManageLoading(false);
  };

  const handleViewInvoices = async () => {
    setInvoicesLoading(true);
    try {
      const url = await generateStripeLink();
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening Stripe portal:", error);
    }
    setInvoicesLoading(false);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "eur":
        return "€";
      case "usd":
        return "$";
      default:
        return currency.toUpperCase();
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const { subscriptions, plans } = dashboardData;

  console.log(subscriptions);

  const getPlanName = (priceId) => {
    const plan = plans.find((plan) =>
      plan.productData.prices.some((price) => price.id === priceId)
    );
    return plan ? plan.name : "Unknown Plan";
  };

  const isExpired = (expirationDate) => {
    const expirationTime = new Date(expirationDate.seconds * 1000).getTime();
    const currentTime = new Date().getTime();
    console.log(new Date(expirationTime)); // Log expiration date
    console.log(new Date(currentTime)); // Log current date
    return expirationTime > currentTime;
  };

  return (
    <div className="center-layout">
      {subscriptions.length === 0 ? (
        <>
          <div className="title-section">
            <h2>Manage Your Subscriptions</h2>
            <p>
              View and manage your active subscriptions and billing details.
            </p>
          </div>
          <p>No active subscriptions</p>
        </>
      ) : (
        <>
          <div className="title-section">
            <h2>Manage Your Subscriptions</h2>
            <p>
              View and manage your active subscriptions and billing details.
            </p>
          </div>
          {subscriptions.map((subscription) => (
            <div key={subscription.id} className="subscription-card">
              <h3>
                <span
                  className={`plan-icon plan-icon-${getPlanName(
                    subscription.items[0].price.id
                  ).toLowerCase()}`}
                ></span>{" "}
                <span>{getPlanName(subscription.items[0].price.id)}</span>
              </h3>
              {subscription.cancel_at_period_end ? (
                <>
                  <p className="subscription-date">
                    {isExpired(subscription.canceled_at)
                      ? "Expired"
                      : "Expires"}{" "}
                    on: {formatDate(subscription.current_period_end)}
                  </p>
                  <p className={`status cancelled`}>
                    {isExpired(subscription.canceled_at)
                      ? "Expired"
                      : "Canceled"}
                  </p>
                </>
              ) : (
                <>
                  <p className="subscription-date">
                    Renew on {formatDate(subscription.current_period_end)} for{" "}
                    {subscription.items && subscription.items.length > 0
                      ? `${getCurrencySymbol(
                          subscription.items[0].price.currency
                        )} ${subscription.items[0].price.unit_amount / 100}`
                      : "N/A"}
                  </p>
                  <p className="status">Active</p>
                </>
              )}
              <div className="actions">
                <span
                  className={`secondary-button ${
                    invoicesLoading ? "loading" : ""
                  }`}
                  onClick={handleViewInvoices}
                >
                  View Invoices
                </span>
                {subscription.cancel_at_period_end ? (
                  <span
                    className={`primary-button ${
                      manageLoading ? "loading" : ""
                    }`}
                    onClick={handleManageSubscription}
                    disabled={manageLoading}
                  >
                    Renew now
                  </span>
                ) : (
                  <span
                    className={`accent-link ${manageLoading ? "loading" : ""}`}
                    onClick={handleManageSubscription}
                    disabled={manageLoading}
                  >
                    Manage Subscription{" "}
                    <span className="icon">open_in_new</span>
                  </span>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Subscriptions;
