import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from "../../../firebase";

const GeneratedContent = ({
  dashboardData,
  selectedFieldId,
  setSelectedFieldId,
  generatedData,
  setGeneratedData,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  handlePageChange,
  publishedFields
}) => {
  const { page } = useParams();
  const navigate = useNavigate();
  const totalPages = Math.ceil(generatedData.length / entriesPerPage);

  useEffect(() => {
    const pageNum = parseInt(page, 10);
    if (isNaN(pageNum) || pageNum < 1 || pageNum > totalPages) {
      navigate('/dashboard/insights/generated/1');
    } else {
      setCurrentPage(pageNum);
    }
  }, [page, navigate, totalPages, setCurrentPage]);

  useEffect(() => {
    let unsubscribeGeneratedData;

    if (dashboardData && selectedFieldId) {
      unsubscribeGeneratedData = subscribeToGeneratedData();
    }

    return () => {
      if (unsubscribeGeneratedData) unsubscribeGeneratedData();
    };
  }, [dashboardData, selectedFieldId]);

  const subscribeToGeneratedData = () => {
    if (dashboardData) {
      const companyId = dashboardData.user.companyId;
      if (!companyId) {
        console.error("Company ID is missing in dashboardData");
        return;
      }

      const generatedDataRef = collection(db, "insights", companyId, "fields", selectedFieldId, "generated_data");

      return onSnapshot(generatedDataRef, (snapshot) => {
        const generatedData = [];
        snapshot.forEach((genDataDoc) => generatedData.push({ id: genDataDoc.id, ...genDataDoc.data() }));

        generatedData.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        setGeneratedData(generatedData);
      }, (error) => {
        console.error("Error fetching generated data:", error);
      });
    }
  };

  // Pagination Logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = generatedData.slice(indexOfFirstEntry, indexOfLastEntry);

  const handleFieldChange = (e) => {
    setSelectedFieldId(e.target.value);
  };

  return (
    <div>
      <h2>Generated Data</h2>
      <label>
        Select Field ID:
        <select value={selectedFieldId} onChange={handleFieldChange}>
          {publishedFields.map((field) => (
            <option key={field.id} value={field.id}>
              {field.name}
            </option>
          ))}
        </select>
      </label>
      <br />
      <label>
        Entries per page:
        <select value={entriesPerPage} onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </label>
      <ul>
        {currentEntries.map((genData) => (
          <li key={genData.id}>
            <ul>
              {genData.prompt && genData.prompt.map((item, index) => (
                <li key={index}>
                  <strong>Question:</strong> {item.question} <br />
                  <strong>Answer:</strong> {item.answer}
                </li>
              ))}
              <li>
                <strong>Date:</strong> {new Date(genData.createdAt.seconds * 1000).toLocaleDateString()} <br />
                <strong>Time:</strong> {new Date(genData.createdAt.seconds * 1000).toLocaleTimeString()} <br />
                {genData.message}
              </li>
            </ul>
          </li>
        ))}
      </ul>
      <div>
        <button
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span> Page {currentPage} of {totalPages} </span>
        <button
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GeneratedContent;
