import React  from "react";
import { Route, Routes } from "react-router-dom";
import DashboardTabs from "../../../components/general/DashboardTabs";
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails"

const AccountSettings = () => {

  const tabs = [
    { title: "Profile", url: "/dashboard/account-settings/profile" },
    {
      title: "Company details",
      url: "/dashboard/account-settings/company-details",
    },
  ];

  return (
    <>
      <DashboardTabs links={tabs} />
      <Routes>
        <Route path="profile" element={<UserDetails/>} />
        <Route path="company-details" element={<CompanyDetails/>} />
      </Routes>
    </>
  );
};

export default AccountSettings;
