import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { db } from "../firebase";
import { doc, collection, getDoc, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, auth } from "../firebase";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const generateStripeLink = async () => {
    if (!auth.currentUser) throw new Error("User is not authenticated");

    try {
      const functions = getFunctions(app, "us-central1");
      const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({
        customerId: auth.currentUser.uid,
        returnUrl: window.location.origin,
      });

      return data.url;
    } catch (error) {
      console.error("Error generating Stripe link:", error);
      throw new Error("Error generating Stripe link");
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        throw new Error("User data not found");
      }
      const userDetails = { id: userSnap.id, ...userSnap.data() };

      const companyRef = doc(db, "companies", userDetails.companyId);
      const companySnap = await getDoc(companyRef);
      if (!companySnap.exists()) {
        throw new Error("Company data not found");
      }
      const companyDetails = companySnap.data();

      const plansCollectionRef = collection(db, "plans");
      const plansSnap = await getDocs(plansCollectionRef);
      const plans = await Promise.all(plansSnap.docs.map(async (planDoc) => {
        const planData = { id: planDoc.id, ...planDoc.data() };
        if (planData.productId) {
          const productRef = doc(db, "stripe_products", planData.productId);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            const productData = productSnap.data();
            const pricesCollectionRef = collection(productRef, "prices");
            const pricesSnap = await getDocs(pricesCollectionRef);
            const prices = pricesSnap.docs.map(priceDoc => ({
              id: priceDoc.id,
              ...priceDoc.data()
            }));
            planData.productData = { ...productData, prices };
          }
        }
        return planData;
      }));

      const activePlanRef = doc(db, "plans", companyDetails.subscriptionPlan);
      const activePlanSnap = await getDoc(activePlanRef);
      const activePlan = activePlanSnap.exists() ? activePlanSnap.data() : null;

      const customerDocRef = doc(db, "stripe_customers", userId);
      const customerSnap = await getDoc(customerDocRef);

      let stripeCustomer = null;
      let payments = [];
      let subscriptions = [];

      if (customerSnap.exists()) {
        stripeCustomer = customerSnap.data();

        const paymentsRef = collection(db, "stripe_customers", userId, "payments");
        const paymentsSnap = await getDocs(paymentsRef);
        payments = paymentsSnap.docs.map((paymentDoc) => ({
          id: paymentDoc.id,
          ...paymentDoc.data(),
        }));

        const subscriptionsRef = collection(db, "stripe_customers", userId, "subscriptions");
        const subscriptionsSnap = await getDocs(subscriptionsRef);
        subscriptions = subscriptionsSnap.docs.map((subscriptionDoc) => ({
          id: subscriptionDoc.id,
          ...subscriptionDoc.data(),
        }));
      }

      await delay(500); // Add delay before setting loading to false
      setDashboardData({
        user: userDetails,
        company: companyDetails,
        plans,
        activePlan,
        stripeCustomer: stripeCustomer ? { ...stripeCustomer, payments } : null,
        subscriptions,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
      setError("Portal is not available at this moment, please try again later.");
    }
  };

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetchUserData(currentUser.uid);
    } else {
      setLoading(false);
      setDashboardData(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <DashboardContext.Provider value={{ dashboardData, fetchUserData, generateStripeLink }}>
      {loading ? (
        <div>Loading ...</div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : (
        children
      )}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
