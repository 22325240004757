import React, { useEffect, useRef, useState } from "react";

const Notification = ({ notification, onClose }) => {
  const onCloseRef = useRef(onClose);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  useEffect(() => {
    let timer;
    if (!isHovering) {
      timer = setTimeout(() => {
        onCloseRef.current();
      }, 30000);
    }

    return () => clearTimeout(timer);
  }, [isHovering]);

  if (!notification) {
    return null;
  }

  return (
    <div className={`notification ${notification.type}`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <div className="content">
          {notification.icon && <span className={`icon ${notification.type}`}>{notification.icon}</span>}
          <div>
          {notification.title && <p className="title">{notification.title}</p>}
          {notification.message && <p className="message">{notification.message}</p>}
          </div>
        </div>
        <button type="button" className="close-btn" onClick={onClose}>
          <span className="icon">close</span>
        </button>
    </div>
  );
};

export default Notification;
