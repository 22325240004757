import React, { useState, useEffect, useRef } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { useTranslation } from "react-i18next";
import { auth, db } from "../../../firebase";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

const Store = () => {
  const { t } = useTranslation();
  const { dashboardData } = useDashboard();
  const [loadingStates, setLoadingStates] = useState({});
  const descriptionRefs = useRef([]);

  const handleCheckout = async (priceId, productId) => {
    if (!priceId) {
      console.error(`Price ID is undefined for productId: ${productId}`);
      return;
    }

    setLoadingStates((prevState) => ({ ...prevState, [productId]: true }));

    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User is not authenticated");
      setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
      return;
    }

    try {
      const checkoutSessionRef = collection(
        db,
        "stripe_customers",
        userId,
        "checkout_sessions"
      );

      let sessionData = {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      };

      const docRef = await addDoc(checkoutSessionRef, sessionData);

      const unsubscribe = onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (!data) {
          console.error("Snapshot data is undefined");
          setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
          return;
        }

        const { error, url } = data;
        if (error) {
          console.error(`An error occurred: ${error.message}`);
          setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
          unsubscribe();
        }
        if (url) {
          unsubscribe();
          window.location.href = url;
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
    }
  };

  const formatValue = (key, value) => {
    if (key === "planLimitations.fills" && value === 0) {
      return t(`${key}`, { value: "Unlimited" });
    }
    if (typeof value === "boolean") {
      return value
        ? t(`${key}`, { value: "Yes" })
        : t(`${key}`, { value: "No" });
    }
    return t(`${key}`, { value });
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "eur":
        return "€";
      case "usd":
        return "$";
      default:
        return currency.toUpperCase();
    }
  };

  const adjustDescriptionHeights = () => {
    const heights = descriptionRefs.current.map(ref => ref ? ref.offsetHeight : 0);
    const maxHeight = Math.max(...heights);
    descriptionRefs.current.forEach(ref => {
      if (ref) ref.style.height = `${maxHeight}px`;
    });
  };

  useEffect(() => {
    adjustDescriptionHeights();
    window.addEventListener("resize", adjustDescriptionHeights);
    return () => window.removeEventListener("resize", adjustDescriptionHeights);
  }, [dashboardData]);

  if (!dashboardData) {
    return <div>{t("store.loading")}</div>;
  }

  const { plans } = dashboardData;

  const renderPlans = (name, mostPopular) => {
    return plans
      .filter((plan) => plan.name === name && plan.name !== "Free")
      .map((plan, index) => {
        const productData = plan.productData;

        if (!productData) {
          console.error(`No productData for plan ${plan.name}`);
        } else if (!productData.prices || productData.prices.length === 0) {
          console.error(`No prices available for productData of plan ${plan.name}`);
        } else {
          console.log(`Prices available for productData of plan ${plan.name}:`, productData.prices);
        }

        const price =
          productData && productData.prices.length > 0
            ? `${getCurrencySymbol(productData.prices[0].currency)} ${
                productData.prices[0].unit_amount / 100
              }`
            : null;
        const priceId = productData && productData.prices.length > 0 ? productData.prices[0].id : null;
        console.log(`Rendering plan ${plan.name}, priceId: ${priceId}`);
        const isLoading = loadingStates[plan.productId];

        return (
          <div key={plan.id} className={`plan ${plan.name.toLowerCase()}`}>
            {mostPopular && <div className="green-label">Most Popular</div>}
            <div className="plan-content">
              <span className={`plan-icon plan-icon-${name.toLowerCase()}`}></span>
              <h3 className="name">{plan.name}</h3>
              <p
                className="description"
                ref={el => descriptionRefs.current[index] = el}
              >
                {t(`store.${plan.description}`)}
              </p>
              {name === "Enterprise" && (
                <p className="enterprise-desc">
                  {t("store.enterpriseDescription")}
                </p>
              )}
              <span className="price">
                {price} {price ? <span className="label">/ month</span> : ""}
              </span>
              {plan.limitations && Object.keys(plan.limitations).length > 0 && (
                <ul className="features">
                  {Object.keys(plan.limitations)
                    .sort()
                    .map(
                      (key) =>
                        key !== "fillsLimit" &&
                        key !== "numberOfUsers" &&
                        key !== "dynamicWizard" && (
                          <li key={key}>
                            <span className="icon">check_circle</span>
                            <p>
                              {formatValue(
                                `planLimitations.${key}`,
                                plan.limitations[key]
                              )}
                            </p>
                          </li>
                        )
                    )}
                </ul>
              )}
            </div>
            <div className="plan-button">
              {name === "Enterprise" ? (
                <a
                  href="mailto:support@yourcompany.com"
                  className="secondary-button"
                >
                  {t("store.contactUs")}
                </a>
              ) : (
                <button
                  className={`${
                    name === "Advanced" ? "primary-button" : "secondary-button"
                  } ${isLoading ? "loading" : ""}`}
                  onClick={() => handleCheckout(priceId, plan.productId)}
                  disabled={isLoading}
                >
                  {isLoading ? t("store.loading") : t("store.subscribe")}
                </button>
              )}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="full-width-layout">
      <div className="title-section">
        <h2>{t("store.title")}</h2>
        <p>{t("store.availablePlans")}</p>
      </div>
      <div className="store-cards">
        {renderPlans("Basic")}
        {renderPlans("Advanced", true)}
        {renderPlans("Enterprise")}
      </div>
    </div>
  );
};

export default Store;
