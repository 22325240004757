import React, { useState, useEffect } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useNotification } from "../../../context/NotificationContext";

const CompanyDetails = () => {
  const { dashboardData } = useDashboard();
  const companyDetails = dashboardData?.company;
  const userCompanyId = dashboardData?.user?.companyId;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: companyDetails?.name || "",
    website: companyDetails?.website || "",
    phone: companyDetails?.contactInfo?.phone || "",
    email: companyDetails?.contactInfo?.email || "",
    billingAddress: companyDetails?.billingAddress || "",
  });

  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFormData({
      name: companyDetails?.name || "",
      website: companyDetails?.website || "",
      phone: companyDetails?.contactInfo?.phone || "",
      email: companyDetails?.contactInfo?.email || "",
      billingAddress: companyDetails?.billingAddress || "",
    });
  }, [companyDetails]);

  const validateField = (name, value) => {
    let error = "";

    if (value.trim() !== "") {
      switch (name) {
        case "email":
          if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i.test(
              value
            )
          ) {
            error = "Invalid email format";
          }
          break;
        case "website":
          if (
            !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
              value
            )
          ) {
            error = "Invalid website format";
          }
          break;
        case "phone":
          if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
            error = "Invalid phone number format";
          }
          break;
        default:
          break;
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Form has errors.");
      return;
    }

    setIsLoading(true);

    try {
      const companyRef = doc(db, "companies", userCompanyId);

      await updateDoc(companyRef, {
        name: formData.name || null,
        website: formData.website || null,
        contactInfo: {
          phone: formData.phone || null,
          email: formData.email || null,
        },
        billingAddress: formData.billingAddress || null,
      });

      addNotification({
        title: "Success!",
        message: "Company details have been updated.",
        icon: "check_circle",
        type: "success",
      });

    } catch (error) {
      console.error("Error updating company details:", error);
      const errorMessage = `Error: ${error?.message || "Unknown error occurred."}`;
      addNotification({
        title: "Something went wrong",
        message: errorMessage,
        icon: "error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isFormInvalid = Object.values(errors).some((error) => error !== "") || isLoading;

  return (
    <div className="profile-layout">
      <div className="description">
        <div className="title-section">
          <h2>Company details</h2>
          <p>A Snapshot of Your Essential Business Information</p>
        </div>
      </div>
      <div className="actions">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className="form-field">
              <label>Company name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                maxLength={100}
                placeholder="Company name"
                className={errors.name ? "has-error" : ""}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
          </fieldset>

          <fieldset>
            <div className="form-field">
              <label>Website</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
                maxLength={200}
                placeholder="Website URL"
                className={errors.website ? "has-error" : ""}
              />
              {errors.website && (
                <div className="error">{errors.website}</div>
              )}
            </div>
          </fieldset>

          <fieldset>
            <div className="form-field">
              <label>Phone number</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                maxLength={15}
                placeholder="Phone number"
                className={errors.phone ? "has-error" : ""}
              />
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
          </fieldset>

          <fieldset>
            <div className="form-field">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxLength={100}
                placeholder="Email address"
                className={errors.email ? "has-error" : ""}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
          </fieldset>

          <fieldset>
            <div className="form-field">
              <label>Company address</label>
              <input
                type="text"
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                maxLength={200}
                placeholder="Company address"
                className={errors.billingAddress ? "has-error" : ""}
              />
              {errors.billingAddress && (
                <div className="error">{errors.billingAddress}</div>
              )}
            </div>
          </fieldset>

          <button
            type="submit"
            className={`primary-button ${isLoading ? "loading" : ""}`}
            disabled={isLoading || isFormInvalid}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompanyDetails;
