import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import DOMPurify from "dompurify";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import PreviewModal from "./PreviewModal";

const FieldForm = () => {
  const { fieldId } = useParams();
  const { dashboardData } = useDashboard();
  const [field, setField] = useState({
    name: "",
    cssSelector: "",
    charLimit: "",
    page: "",
    language: "English",
    fieldDescription: "",
    storeData: false,
    published: false,
    questions: [],
    active: true,
  });

  console.log(dashboardData);
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFieldLoaded, setIsFieldLoaded] = useState(false);

  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const planLimitations = dashboardData?.activePlan?.limitations || {};
  const maxSteps = planLimitations?.steps || 1;
  const canAddConditionals = planLimitations?.conditionals || false;
  const maxOptions = 20; // Maximum number of options

  useEffect(() => {
    if (fieldId) {
      const existingField = dashboardData.company.fields.find(
        (f) => f.id === fieldId
      );
      if (existingField) {
        setField(existingField);
      }

      setIsFieldLoaded(true);
    }
  }, [fieldId, dashboardData.company.fields]);

  useEffect(() => {
    const checkRoute = () => {
      if (isFieldLoaded) {
        if (location.pathname.endsWith("/preview")) {
          if (field.questions.length === 0) {
            navigate(location.pathname.replace("/preview", ""), {
              replace: true,
            });
          } else {
            setIsModalOpen(true);
          }
        } else {
          setIsModalOpen(false);
        }
      }
    };

    checkRoute();

    return () => {
      checkRoute();
    };
  }, [location.pathname, field.questions.length, navigate, isFieldLoaded]);

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    const sanitizedValue = DOMPurify.sanitize(
      type === "checkbox" ? checked : value
    );
    setField({
      ...field,
      [name]: sanitizedValue,
    });
    if (errors[name]) {
      validateFields();
    }
  };

  const handleQuestionChange = (index, name, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[index][name] = sanitizedValue;
    setField({ ...field, questions: newQuestions });
    if (
      errors[`question${index}`] ||
      errors[`question${index}options`] ||
      errors[`question${index}condition`] ||
      errors[`question${index}value`]
    ) {
      validateFields();
    }
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].options[optionIndex] = sanitizedValue;
    setField({ ...field, questions: newQuestions });
  };

  const handleConditionChange = (questionIndex, name, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].conditions = {
      ...newQuestions[questionIndex].conditions,
      [name]: sanitizedValue,
    };
    setField({ ...field, questions: newQuestions });
    if (
      errors[`question${questionIndex}condition`] ||
      errors[`question${questionIndex}value`]
    ) {
      validateFields();
    }
  };

  const addQuestion = () => {
    if (validateFields()) {
      if (field.questions.length < maxSteps) {
        setField((prevField) => {
          const newQuestions = [
            ...prevField.questions,
            {
              quote: "",
              type: "text",
              placeholder: "",
              options: [],
              hasConditional: false,
              conditions: { slideNo: "", condition: "equals", value: "" },
            },
          ];
          return { ...prevField, questions: newQuestions };
        });
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        addNotification({
          title: "Error",
          message: `You can only add up to ${maxSteps} steps.`,
          icon: "error",
          type: "error",
        });
      }
    }
  };

  const addOption = (index) => {
    const newQuestions = [...field.questions];
    if (!Array.isArray(newQuestions[index].options)) {
      newQuestions[index].options = [];
    }
    if (newQuestions[index].options.length < maxOptions) {
      newQuestions[index].options.push("");
      setField({ ...field, questions: newQuestions });
    } else {
      addNotification({
        title: "Error",
        message: `You can only add up to ${maxOptions} options.`,
        icon: "error",
        type: "error",
      });
    }
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...field.questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setField({ ...field, questions: newQuestions });
    validateFields();
  };

  const removeQuestion = (index) => {
    const newQuestions = [...field.questions];
    newQuestions.splice(index, 1);
    setField({ ...field, questions: newQuestions });
    setCurrentStep((prevStep) =>
      prevStep > newQuestions.length ? prevStep - 1 : prevStep
    );
  };

  const validateFields = () => {
    let valid = true;
    let newErrors = {};

    // Validate main field inputs
    if (!field.name) {
      newErrors.name = "Field name is required.";
      valid = false;
    } else if (field.name.length > 100) {
      newErrors.name = "Field name cannot exceed 100 characters.";
      valid = false;
    }
    if (!field.cssSelector) {
      newErrors.cssSelector = "CSS selector is required.";
      valid = false;
    } else if (!isValidCSSSelector(field.cssSelector)) {
      newErrors.cssSelector = "CSS selector is invalid.";
      valid = false;
    }
    if (!field.charLimit) {
      newErrors.charLimit = "Maximum length is required.";
      valid = false;
    } else if (!/^\d+$/.test(field.charLimit)) {
      newErrors.charLimit = "Maximum length must be a number.";
      valid = false;
    }
    if (!field.language) {
      newErrors.language = "Field language is required.";
      valid = false;
    }

    // Validate questions
    field.questions.forEach((question, index) => {
      if (!question.quote) {
        newErrors[`question${index}`] = "Question name is required.";
        valid = false;
      } else if (question.quote.length > 200) {
        newErrors[`question${index}`] =
          "Question name cannot exceed 200 characters.";
        valid = false;
      }
      if (
        (question.type === "singleSelect" || question.type === "multiSelect") &&
        question.options.length === 0
      ) {
        newErrors[`question${index}options`] =
          "Options are required for select type questions.";
        valid = false;
      }
      if (question.hasConditional) {
        if (!question.conditions.slideNo || question.conditions.slideNo <= 0) {
          newErrors[`question${index}condition`] =
            "Slide no must be a positive number.";
          valid = false;
        }
        if (!question.conditions.value) {
          newErrors[`question${index}value`] = "Condition value is required.";
          valid = false;
        }
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const isValidCSSSelector = (selector) => {
    const cssSelectorRegex = /^([#.]?[-\w]+|\*)(\s+([#.]?[-\w]+|\*))*$/;
    return cssSelectorRegex.test(selector);
  };

  const saveField = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      const companyId = dashboardData.user.companyId;
      const companyRef = doc(db, `companies`, companyId);
      let updatedFields;

      if (fieldId) {
        updatedFields = dashboardData.company.fields.map((f) =>
          f.id === fieldId ? { ...field } : f
        );
      } else {
        const newField = { ...field, id: generateUniqueId() };
        updatedFields = [...dashboardData.company.fields, newField];
      }

      await setDoc(companyRef, { fields: updatedFields }, { merge: true });

      addNotification({
        title: "Success!",
        message: "Field saved successfully.",
        icon: "check_circle",
        type: "success",
      });
    } catch (error) {
      console.error("Error saving field:", error);
      addNotification({
        title: "Error",
        message: `Error saving field: ${error.message}`,
        icon: "error",
        type: "error",
      });
    }
  };

  const generateUniqueId = () =>
    `${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 9)}`;

  const navigateToStep = (step) => {
    setCurrentStep(step);
  };

  const openPreviewModal = () => {
    navigate(`${location.pathname}/preview`);
  };

  const closePreviewModal = () => {
    navigate(location.pathname.replace("/preview", ""));
  };

  const isPreviewButtonEnabled = () => {
    return field.questions.some((question) => {
      return (
        question.quote &&
        question.type &&
        (question.type === "text" ||
          (question.type === "singleSelect" && question.options.length > 0) ||
          (question.type === "multiSelect" && question.options.length > 0))
      );
    });
  };

  return (
    <div className="center-layout fields-editor">
      <div className="description">
        <div className="title-section">
          <h2>{fieldId ? "Edit field" : "Add your new field"}</h2>
          <p>
            Configure your new form field, where FillyForm will prompt users to
            help fill in the required text
          </p>
        </div>
      </div>
      <div className="steps-nav-wrapper">
        <div className="steps-nav">
          {isPreviewButtonEnabled() && (
            <span className="step-number icon" onClick={openPreviewModal}>
              preview
            </span>
          )}
          <span
            className={`step-number icon ${currentStep === 1 ? "active" : ""}`}
            onClick={() => navigateToStep(1)}
          >
            settings
          </span>
          {Array.from({ length: field.questions.length }, (_, i) => (
            <span
              key={i + 2}
              className={`step-number ${currentStep === i + 2 ? "active" : ""}`}
              onClick={() => navigateToStep(i + 2)}
            >
              {i + 1}
            </span>
          ))}
        </div>
      </div>
      <div className="form">
        {currentStep === 1 && (
          <>
            <div className={`form-field ${errors.name ? "has-error" : ""}`}>
              <label>
                Field name - this will be used internally for identification
              </label>
              <input
                type="text"
                name="name"
                value={field?.name || ""}
                onChange={handleFieldChange}
                placeholder="Job application - personal description"
                maxLength="100"
                className={errors.name ? "has-error" : ""}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div
              className={`form-field ${errors.cssSelector ? "has-error" : ""}`}
            >
              <label>
                Field CSS selector{" "}
                <a href="google.com" target="_blank" className="link">
                  See guide
                </a>
              </label>
              <input
                type="text"
                name="cssSelector"
                value={field?.cssSelector || ""}
                onChange={handleFieldChange}
                placeholder=".hs-form-field > .first-name"
                className={errors.cssSelector ? "has-error" : ""}
              />
              {errors.cssSelector && (
                <div className="error">{errors.cssSelector}</div>
              )}
            </div>
            <div
              className={`form-field ${errors.charLimit ? "has-error" : ""}`}
            >
              <label>Maximum length - maximum number of chars accepted</label>
              <input
                type="number"
                name="charLimit"
                value={field?.charLimit || ""}
                onChange={handleFieldChange}
                placeholder="250"
                className={errors.charLimit ? "has-error" : ""}
              />
              {errors.charLimit && (
                <div className="error">{errors.charLimit}</div>
              )}
            </div>
            <div
              className={`form-field ${
                errors.fieldDescription ? "has-error" : ""
              }`}
            >
              <label>Field description </label>
              <input
                type="text"
                name="fieldDescription"
                value={field?.fieldDescription || ""}
                onChange={handleFieldChange}
                placeholder="What's this field about"
                className={errors.fieldDescription ? "has-error" : ""}
              />
              {errors.fieldDescription && (
                <div className="error">{errors.fieldDescription}</div>
              )}
            </div>
            <div className={`form-field ${errors.language ? "has-error" : ""}`}>
              <label>Field language</label>
              <select
                name="language"
                value={field.language}
                onChange={handleFieldChange}
                className={errors.language ? "has-error" : ""}
              >
                <option value="English">English</option>
                {/* Add other language options as needed */}
              </select>
              {errors.language && (
                <div className="error">{errors.language}</div>
              )}
            </div>
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="storeData"
                  checked={!!field.storeData}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator"></span> Store generated
                data
              </label>
            </div>
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="published"
                  checked={!!field.published}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator"></span> Published
              </label>
            </div>
            <button onClick={addQuestion} className="secondary-button">
              Next
            </button>
            <button onClick={saveField} className="primary-button">
              Save
            </button>
          </>
        )}
        {currentStep > 1 && currentStep <= field.questions.length + 1 && (
          <>
            <div
              className={`form-field ${
                errors[`question${currentStep - 2}`] ? "has-error" : ""
              }`}
            >
              <label>Question name (type your question)</label>
              <input
                type="text"
                value={field.questions[currentStep - 2].quote}
                onChange={(e) =>
                  handleQuestionChange(currentStep - 2, "quote", e.target.value)
                }
                placeholder="What’s the name of your product?"
                maxLength="200"
                className={
                  errors[`question${currentStep - 2}`] ? "has-error" : ""
                }
              />
              {errors[`question${currentStep - 2}`] && (
                <div className="error">
                  {errors[`question${currentStep - 2}`]}
                </div>
              )}
            </div>
            <div className="form-field">
              <label>Answer type</label>
              <select
                value={field.questions[currentStep - 2].type}
                onChange={(e) =>
                  handleQuestionChange(currentStep - 2, "type", e.target.value)
                }
              >
                <option value="text">Text</option>
                <option value="singleSelect">Single select</option>
                <option value="multiSelect">Multi select</option>
              </select>
            </div>
            {field.questions[currentStep - 2].type === "text" && (
              <div className="form-field">
                <label>Placeholder text</label>
                <input
                  type="text"
                  value={field.questions[currentStep - 2].placeholder}
                  onChange={(e) =>
                    handleQuestionChange(
                      currentStep - 2,
                      "placeholder",
                      e.target.value
                    )
                  }
                  placeholder="e.g.: ACME Inc."
                  maxLength="100"
                />
              </div>
            )}
            {field.questions[currentStep - 2].type === "singleSelect" && (
              <div
                className={`form-field options-wrapper ${
                  errors[`question${currentStep - 2}options`] ? "has-error" : ""
                }`}
              >
                <label>Add options</label>
                {Array.isArray(field.questions[currentStep - 2].options) &&
                  field.questions[currentStep - 2].options.map(
                    (option, index) => (
                      <div
                        key={index}
                        className="option-field field-actions-wrapper"
                      >
                        <input
                          type="text"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(
                              currentStep - 2,
                              index,
                              e.target.value
                            )
                          }
                          placeholder={`Option ${index + 1}`}
                          maxLength="100"
                          className={
                            errors[`question${currentStep - 2}options`]
                              ? "has-error"
                              : ""
                          }
                        />
                        <span
                          className="icon accent-link"
                          onClick={() => removeOption(currentStep - 2, index)}
                        >
                          delete
                        </span>
                      </div>
                    )
                  )}
                {errors[`question${currentStep - 2}options`] && (
                  <div className="error">
                    {errors[`question${currentStep - 2}options`]}
                  </div>
                )}
                {field.questions[currentStep - 2].options.length < maxOptions && (
                  <span
                    className="accent-link"
                    onClick={() => addOption(currentStep - 2)}
                  >
                    Add new +
                  </span>
                )}
              </div>
            )}
            {field.questions[currentStep - 2].type === "multiSelect" && (
              <div
                className={`form-field options-wrapper ${
                  errors[`question${currentStep - 2}options`] ? "has-error" : ""
                }`}
              >
                <label>Add options</label>
                {Array.isArray(field.questions[currentStep - 2].options) &&
                  field.questions[currentStep - 2].options.map(
                    (option, index) => (
                      <div key={index} className="option-field">
                        <input
                          type="text"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(
                              currentStep - 2,
                              index,
                              e.target.value
                            )
                          }
                          placeholder={`Option ${index + 1}`}
                          maxLength="100"
                          className={
                            errors[`question${currentStep - 2}options`]
                              ? "has-error"
                              : ""
                          }
                        />
                        <span
                          className="icon accent-link"
                          onClick={() => removeOption(currentStep - 2, index)}
                        >
                          delete
                        </span>
                      </div>
                    )
                  )}
                {errors[`question${currentStep - 2}options`] && (
                  <div className="error">
                    {errors[`question${currentStep - 2}options`]}
                  </div>
                )}
                {field.questions[currentStep - 2].options.length < maxOptions && (
                  <span
                    className="accent-link"
                    onClick={() => addOption(currentStep - 2)}
                  >
                    Add new +
                  </span>
                )}
              </div>
            )}
            {canAddConditionals && currentStep > 2 && (
              <>
                <div className="form-field-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        !!field.questions[currentStep - 2].hasConditional ||
                        false
                      }
                      onChange={(e) =>
                        handleQuestionChange(
                          currentStep - 2,
                          "hasConditional",
                          e.target.checked
                        )
                      }
                      className="visually-hidden custom-checkbox"
                    />
                    <span className="checkbox-indicator"></span> Add display
                    condition
                  </label>
                </div>
                {field.questions[currentStep - 2].hasConditional && (
                  <div className="form-field conditions">
                    <div className="slide-no">
                      <label>Slide no</label>
                      <select
                        value={
                          field.questions[currentStep - 2].conditions
                            ?.slideNo || ""
                        }
                        onChange={(e) =>
                          handleConditionChange(
                            currentStep - 2,
                            "slideNo",
                            e.target.value
                          )
                        }
                        className={
                          errors[`question${currentStep - 2}condition`]
                            ? "has-error"
                            : ""
                        }
                      >
                        {Array.from({ length: currentStep - 1 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                      {errors[`question${currentStep - 2}condition`] && (
                        <div className="error">
                          {errors[`question${currentStep - 2}condition`]}
                        </div>
                      )}
                    </div>
                    <div className="condition-value">
                      <label>Condition</label>
                      <select
                        value={
                          field.questions[currentStep - 2].conditions
                            ?.condition || "equals"
                        }
                        onChange={(e) =>
                          handleConditionChange(
                            currentStep - 2,
                            "condition",
                            e.target.value
                          )
                        }
                      >
                        <option value="equals">Equals</option>
                        <option value="notequals">Not equals</option>
                        <option value="contains">Contains</option>
                        <option value="notcontains">Not contains</option>
                      </select>
                    </div>
                    <div className="value">
                      <label>Value</label>
                      <input
                        type="text"
                        value={
                          field.questions[currentStep - 2].conditions?.value ||
                          ""
                        }
                        onChange={(e) =>
                          handleConditionChange(
                            currentStep - 2,
                            "value",
                            e.target.value
                          )
                        }
                        placeholder="Value"
                        maxLength="100"
                        className={
                          errors[`question${currentStep - 2}value`]
                            ? "has-error"
                            : ""
                        }
                      />
                      {errors[`question${currentStep - 2}value`] && (
                        <div className="error">
                          {errors[`question${currentStep - 2}value`]}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {currentStep < field.questions.length + 1 && (
              <button
                onClick={() => setCurrentStep(currentStep + 1)}
                className="secondary-button"
              >
                Next
              </button>
            )}
            {currentStep === field.questions.length + 1 && (
              <button onClick={addQuestion} className="secondary-button">
                Add new
              </button>
            )}
            <button onClick={saveField} className="primary-button">
              Save
            </button>
            <button
              onClick={() => removeQuestion(currentStep - 2)}
              className="secondary-button"
            >
              Delete
            </button>
          </>
        )}
      </div>
      <PreviewModal
        isOpen={isModalOpen}
        onClose={closePreviewModal}
        field={field}
        companyId={dashboardData.user.companyId}
      />
    </div>
  );
};

export default FieldForm;
