import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, Timestamp, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import { db } from "../../../firebase";

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement);

const InsightsData = ({ dashboardData, selectedFieldId, setSelectedFieldId }) => {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [startDate, setStartDate] = useState(sevenDaysAgo.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [insightsData, setInsightsData] = useState([]);
  const [chartType, setChartType] = useState("Bar");
  const [dateRange, setDateRange] = useState("Last 7 days");

  const publishedFields = dashboardData?.company?.fields?.filter(field => field.published === "true") || [];

  useEffect(() => {
    let unsubscribeEvents;

    if (selectedFieldId && startDate && endDate) {
      unsubscribeEvents = subscribeToEvents();
    }

    return () => {
      if (unsubscribeEvents) unsubscribeEvents();
    };
  }, [selectedFieldId, startDate, endDate]);

  const subscribeToEvents = useCallback(() => {
    if (dashboardData) {
      const companyId = dashboardData.user.companyId;
      const fieldsRef = collection(db, "insights", companyId, "fields", selectedFieldId, "events");

      const startTimestamp = Timestamp.fromDate(new Date(startDate + "T00:00:00"));
      const endTimestamp = Timestamp.fromDate(new Date(endDate + "T23:59:59"));

      const q = query(fieldsRef, where("createdAt", ">=", startTimestamp), where("createdAt", "<=", endTimestamp), orderBy("createdAt"), limit(1000));

      return onSnapshot(q, (snapshot) => {
        const insights = [];
        snapshot.forEach((doc) => insights.push({ id: doc.id, ...doc.data() }));

        setInsightsData(insights);
      }, (error) => {
        console.error("Error fetching insights:", error);
      });
    }
  }, [dashboardData, selectedFieldId, startDate, endDate]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleFieldChange = (e) => {
    setSelectedFieldId(e.target.value);
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
    const today = new Date();
    let newStartDate, newEndDate;

    switch (e.target.value) {
      case "Today":
        newStartDate = newEndDate = today;
        break;
      case "Yesterday":
        newStartDate = newEndDate = new Date(today.setDate(today.getDate() - 1));
        break;
      case "Last 7 days":
        newStartDate = new Date(today.setDate(today.getDate() - 7));
        newEndDate = new Date();
        break;
      case "Last 30 days":
        newStartDate = new Date(today.setDate(today.getDate() - 30));
        newEndDate = new Date();
        break;
      case "This month":
        newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
        newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "Last month":
        newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "This year":
        newStartDate = new Date(today.getFullYear(), 0, 1);
        newEndDate = new Date(today.getFullYear(), 11, 31);
        break;
      case "Last year":
        newStartDate = new Date(today.getFullYear() - 1, 0, 1);
        newEndDate = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case "Custom range":
        newStartDate = new Date(today.setDate(today.getDate() - 7));
        newEndDate = new Date();
        break;
      default:
        newStartDate = new Date(today.setDate(today.getDate() - 7));
        newEndDate = new Date();
    }

    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);
  };

  const processData = (data) => {
    const eventTypes = ['display', 'click', 'fill'];

    let dateMap = {};

    if (!data || data.length === 0) {
      return { labels: [], datasets: [] };
    }

    if (dateRange === "This year" || dateRange === "Last year") {
      dateMap = eventTypes.reduce((acc, type) => {
        acc[type] = data.reduce((typeAcc, item) => {
          const date = new Date(item.createdAt.seconds * 1000);
          const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
          if (!typeAcc[monthYear]) {
            typeAcc[monthYear] = 0;
          }
          typeAcc[monthYear] += item.event === type ? 1 : 0;
          return typeAcc;
        }, {});
        return acc;
      }, {});
    } else {
      dateMap = eventTypes.reduce((acc, type) => {
        acc[type] = data.reduce((typeAcc, item) => {
          const date = new Date(item.createdAt.seconds * 1000).toLocaleDateString();
          if (!typeAcc[date]) {
            typeAcc[date] = 0;
          }
          typeAcc[date] += item.event === type ? 1 : 0;
          return typeAcc;
        }, {});
        return acc;
      }, {});
    }

    const labels = Object.keys(dateMap.display || {});
    const datasets = eventTypes.map((type, index) => ({
      label: `${type.charAt(0).toUpperCase() + type.slice(1)} Events`,
      data: labels.map(label => dateMap[type][label] || 0),
      backgroundColor: ["rgba(75,192,192,0.6)", "rgba(153,102,255,0.6)", "rgba(255,159,64,0.6)"][index],
      borderColor: ["rgba(75,192,192,1)", "rgba(153,102,255,1)", "rgba(255,159,64,1)"][index],
      borderWidth: 2,
      borderRadius: 10,
    }));

    return {
      labels,
      datasets,
    };
  };

  const chartOptions = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#333',
          font: {
            size: 14,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
  };

  const renderChart = () => {
    const data = processData(insightsData);

    switch (chartType) {
      case "Bar":
        return <Bar data={data} options={chartOptions} />;
      case "Line":
        return <Line data={data} options={chartOptions} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <h2>Events</h2>
      <label>
        Date Range:
        <select value={dateRange} onChange={handleDateRangeChange}>
          <option value="Today">Today</option>
          <option value="Yesterday">Yesterday</option>
          <option value="Last 7 days">Last 7 days</option>
          <option value="Last 30 days">Last 30 days</option>
          <option value="This month">This month</option>
          <option value="Last month">Last month</option>
          <option value="This year">This year</option>
          <option value="Last year">Last year</option>
          <option value="Custom range">Custom range</option>
        </select>
      </label>
      <br />
      {dateRange === "Custom range" && (
        <>
          <label>
            Start Date:
            <input type="date" value={startDate} onChange={handleStartDateChange} />
          </label>
          <br />
          <label>
            End Date:
            <input type="date" value={endDate} onChange={handleEndDateChange} />
          </label>
          <br />
        </>
      )}
      <label>
        Select Field ID:
        <select value={selectedFieldId} onChange={handleFieldChange}>
          {publishedFields.map((field) => (
            <option key={field.id} value={field.id}>
              {field.name}
            </option>
          ))}
        </select>
      </label>
      <br />
      <label>
        Select Chart Type:
        <select value={chartType} onChange={handleChartTypeChange}>
          <option value="Bar">Bar</option>
          <option value="Line">Line</option>
        </select>
      </label>
      {renderChart()}
    </div>
  );
};

export default InsightsData;
