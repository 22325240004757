import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import { doc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import { useNavigate } from 'react-router-dom';

const Fields = () => {
  const { dashboardData } = useDashboard();
  const [fields, setFields] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const navigate = useNavigate();

  const { addNotification } = useNotification();

  console.log(dashboardData);

  useEffect(() => {
    if (dashboardData.company.fields) {
      const fieldsArray = Array.isArray(dashboardData.company.fields) ? dashboardData.company.fields : Object.values(dashboardData.company.fields);
      setFields(fieldsArray);
    }
  }, [dashboardData.company.fields]);

  const addField = () => {
    navigate('/dashboard/fields/add');
  };

  const confirmDeleteField = (index) => {
    setFieldToDelete(index);
    setShowDeleteModal(true);
  };

  const deleteField = async () => {
    try {
      const fieldToRemove = fields[fieldToDelete];
      const newFields = [...fields];
      newFields.splice(fieldToDelete, 1);

      const companyRef = doc(db, 'companies', dashboardData.user.companyId);
      await updateDoc(companyRef, {
        fields: arrayRemove(fieldToRemove),
      });

      // await fetchUserData(dashboardData.user.id); // Refresh data after deletion

      setFields(newFields);
      setShowDeleteModal(false);

      addNotification({
        title: "Success!",
        message: "Field deleted successfully.",
        icon: "check_circle",
        type: "success",
      });
    } catch (error) {
      console.error("Error deleting field:", error);
      addNotification({
        title: "Error",
        message: `Error deleting field: ${error.message}`,
        icon: "error",
        type: "error",
      });
    }
  };

  return (
    <div className="center-layout">
      <div className="description">
        <div className="title-section">
          <h2>Your fields</h2>
          <p>Define the fields where you want FillyForm to help your users complete magically fill</p>
        </div>
      </div>
      <div className="actions">
        {fields.length === 0 ? (
          <div className="no-fields">
            <p>No fields available. Add a new field to get started.</p>
            <button className="primary-button" onClick={addField}>Add new</button>
          </div>
        ) : (
          <>
            <ul className="fields-list">
              {fields.map((field, index) => (
                <li key={field.id} className="field-item">
                  <div className="field-info">
                    <h3>{field.name}</h3>
                    <span>{field.charLimit} char limit</span>
                  </div>
                  <div className="field-actions">
                    <span className="icon" onClick={() => navigate(`/dashboard/fields/edit/${field.id}`)}>edit</span>
                    <span className="icon" onClick={() => confirmDeleteField(index)}>delete</span>
                  </div>
                </li>
              ))}
            </ul>
            <button className="primary-button" onClick={addField}>Add new</button>
          </>
        )}
      </div>

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close icon" onClick={() => setShowDeleteModal(false)}>close</span>
            <div className="icon modal-icon">
                delete
              </div>
            <h2>Are you sure you want to delete this field?</h2>
            <div className="modal-actions">
              <button className="primary-button" onClick={() => setShowDeleteModal(false)}>Cancel</button>
              <button className="secondary-button" onClick={deleteField}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Fields;
